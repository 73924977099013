/**
 * External Dependencies
 */
import { combineReducers } from 'redux';
import Omit from 'object.omit';

/**
 * Internal Dependencies
 */
import { getUID } from '../utils';
import defaultSettings from '../settings';
import {
    UPDATE_AUTH,
    UPDATE_INIT,
    UPDATE_SETTINGS,
    UPDATE_USER_CONFIG,
    ADD_TOAST,
    REMOVE_TOAST,
    SIGNIN_USER,
    SIGNIN_USER_SUCCESS,
    SIGNIN_USER_FAILURE,
    SIGNIN_USER_RESET,
    UPDATE_WORKSPACES,
    UPDATE_VTHINGS,
    UPDATE_DEVICES,
    MESSENGER_ADD,
    RENAME_WORKSPACES,
} from '../actions';

import { UPDATE_WORKSPACE_SETTINGS, VALIDATE_CGU } from '../actions/users';

import { LOGOUT_USER } from '../store/network-service';
import gael from './gael';
import scripts from './scripts';
import { update_css } from '../tools/css';

// initial state.
const INITIAL_SETTINGS_STATE = {
    ...defaultSettings,
};
const INITIAL_AUTH_STATE = {
    loading: false,
    error: null,
    token: '',
    login: '',
    acls: {},
    limited: false,
    restricted: [],
    role: 'none',
    scopes: {},
    avatar: { data: '' },
};
const INITIAL_WORKSPACES_STATE = [];
const INITIAL_VTHINGS_STATE = {
    loading: false,
    error: null,
    vthings: [],
};

const INITIAL_TOASTS_STATE = [];

const INITIAL_APP_STATE = {
    version: process.env.REACT_APP_VERSION,
    initialized: false,
    query: {},
    settings: {
        open_scripts: [],
        active_tab: 'scripts',
    },
};

const INITIAL_MESSENGER_STATE = { current: '', devices: {} };

const INITIAL_DEVICE_ICON_STATE = {
    smartlight: { icon: ['fas', 'lightbulb'] },
    energymeter: { icon: ['fas', 'bolt'] },
    wiki: { icon: ['fab', 'wikipedia-w'] },
    pollutioncity: { icon: ['fas', 'building'] },
    group: { icon: ['fas', 'folder'] },
    social: { icon: ['fas', 'comment'] },
    weather: { icon: ['fas', 'cloud-sun'] },
    sensor: { icon: ['fas', 'thermometer-half'] },
    interrupter: { icon: ['fas', 'power-off'] },
    maintenance: { icon: ['fas', 'wrench'] },
};

/**
 * Reducer
 */
const rootReducer = combineReducers({
    vthings: (state = INITIAL_VTHINGS_STATE, action) => {
        switch (action.type) {
            case UPDATE_VTHINGS: {
                action.payload.vthings.forEach((vthing, idx) => {
                    const label = /[^/]+\/vthing_(?<label>[^:]*):.*/.exec(vthing.image)?.groups?.label || `vthing ${idx}`;
                    vthing.label = label.replace(/_/g, ' ');
                });
                return action.payload;
            }
            default:
                return state;
        }
    },
    workspaces: (state = INITIAL_WORKSPACES_STATE, action) => {
        switch (action.type) {
            case UPDATE_WORKSPACES:
                return action.workspaces || [];
            case RENAME_WORKSPACES: {
                const index = state.indexOf((val) => {
                    return val.workspace == action.workspace_name;
                });
                if (index == -1) return state;
                const ret = [...state];
                ret[index] = {
                    workspace: action.workspace_name,
                    label: action.newLabel,
                };
                return ret;
            }
            default:
                return state;
        }
    },
    messenger: (state = INITIAL_MESSENGER_STATE, action) => {
        switch (action.type) {
            case UPDATE_INIT: {
                if (!action.payload.query.ws || state.current === action.payload.query.ws) return state;
                const buffer = localStorage.getItem(`messenger_${action.payload.query.ws}`);
                const backup = buffer ? JSON.parse(buffer) : { current: action.payload.query.ws, devices: {} };
                return { ...state, ...backup };
            }
            case MESSENGER_ADD: {
                const {device, message, stream} = action.payload;
                const current = (state.devices[device] || []).slice(-100);
                const newState = { ...state };
                let replace = null;
                if (stream === true && message.messageId) {
                    for (const msg of current) {
                        if (msg.messageId === message.messageId){
                            replace = msg;
                            break;
                        }
                    }
                }
                if (replace) {
                    replace.text = message.text;
                } else {
                    current.push(message);
                }
                newState.devices[device] = current;
                localStorage.setItem(`messenger_${newState.current}`, JSON.stringify(newState));
                return newState;
            }
            default:
                return state;
        }
    },
    app: (state = INITIAL_APP_STATE, action) => {
        switch (action.type) {
            case UPDATE_USER_CONFIG:
                return { ...state, query: { ...state.query, ws: action.config.ws } };
            case UPDATE_INIT:
                return { ...state, ...action.payload };
            case UPDATE_WORKSPACE_SETTINGS:
                if (action.workspace == state.query.ws) {
                    const newState = { ...state, settings: { ...state.settings, ...action.settings } };
                    if (newState.settings.active_tab == '') {
                        newState.settings.active_tab = 'scripts';
                    }
                    if (!action.settings.theme) {
                        delete newState.settings.theme;
                    }
                    return newState;
                }
            default:
                return state;
        }
    },
    auth: (state = INITIAL_AUTH_STATE, action) => {
        switch (action.type) {
            case SIGNIN_USER:
                return { ...INITIAL_AUTH_STATE, loading: true };
            case SIGNIN_USER_SUCCESS: {
                localStorage.setItem('x-token', action.payload.token);
                const acls = {};
                try {
                    if (Array.isArray(action.payload.acl)) {
                        for (const control of action.payload.acl) {
                            const ws = control.workspace;
                            const acl = acls[ws] || {};
                            const isManagedNLP = control.nlp == 'managed';
                            acl.managed = isManagedNLP;
                            control.actions.forEach((a) => {
                                const [type, access] = a.split(':');
                                acl[type] = [...(acl[type] || (isManagedNLP ? ['limited'] : [])), access];
                            });
                            acls[ws] = acl;
                        }
                    }
                } catch (ex) {
                    //
                }
                return {
                    ...state,
                    loading: false,
                    error: null,
                    cgu: action.payload.cgu,
                    acls,
                    scopes: action.payload.scopes,
                    token: action.payload.token,
                    login: action.payload.login,
                    role: action.payload.role,
                    avatar: action.payload.avatar,
                };
            }
            case VALIDATE_CGU:
                return { ...state, cgu: undefined };
            case SIGNIN_USER_FAILURE:
                localStorage.removeItem('x-token');
                return {
                    ...state,
                    loading: false,
                    error: action.payload.message,
                    token: '',
                    login: '',
                    role: 'none',
                    scopes: {},
                    avatar: { data: '' },
                };
            case UPDATE_AUTH:
                return { ...state, ...action.auth };
            case LOGOUT_USER:
                localStorage.removeItem('x-token');
            // fallsthrough
            case SIGNIN_USER_RESET:
                return { ...INITIAL_AUTH_STATE };
            default:
                return state;
        }
    },
    devicesIcons: (state = INITIAL_DEVICE_ICON_STATE, action) => {
        switch (action.type) {
            case UPDATE_DEVICES:
                return { ...state, ...action.settings };
            default:
                return state;
        }
    },
    settings: (state = INITIAL_SETTINGS_STATE, action) => {
        switch (action.type) {
            case UPDATE_SETTINGS:
                if (action.settings.color_brand && action.settings.color_brand != state.color_brand) {
                    if (!update_css(action.settings.color_brand)) {
                        delete action.settings.color_brand;
                    }
                }
                return { ...state, ...action.settings };
            default:
                return state;
        }
    },
    gael,
    scripts,
    toasts: (state = INITIAL_TOASTS_STATE, action) => {
        switch (action.type) {
            case ADD_TOAST:
                // eslint-disable-next-line no-case-declarations
                const newData = {
                    ...{
                        title: '',
                        content: '',
                        color: 'brand',
                        time: false,
                        duration: 0,
                        closeButton: true,
                    },
                    ...action.data,
                };

                if (newData.time === true) {
                    newData.time = new Date();
                }

                return {
                    ...state,
                    [getUID()]: newData,
                };
            case REMOVE_TOAST:
                if (!action.id || !state[action.id]) {
                    return state;
                }
                return Omit(state, action.id);
            default:
                return state;
        }
    },
});

export default rootReducer;
