/**
 * External Dependencies
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import classnames from 'classnames/dedupe';

/**
 * Internal Dependencies
 */
import AsyncComponent from '../../components/async-component';
import PageWrap from '../../components/page-wrap';
import PageTitle from '../../components/page-title';
import PageContent from '../../components/page-content';
import Tabs from '../../components/tabs';
import Icon from '../../components/icon';
import { SCRIPT_ACTIONS } from '../../types/event';
import ListProvider, { TasksContext } from './TasksList/ListProvider';
import { ScriptTypes, ScriptTypesIcon } from './components/ItemTypes';
import TabNavItem from '../../components/tab-navItem-tips';

/**
 * Component
 */
class TasksManagementPage extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <PageWrap>
                <PageTitle>
                    <h1>Developer Studio</h1>
                </PageTitle>
                <ListProvider>
                    <PageContent>
                        <TasksContext.Consumer>
                            {(context) => {
                                return (
                                    <>
                                        <Tabs sliding className="agora-script-tabs">
                                            <Tabs.NavItem
                                                isActive={context.state.active_tab === 'scripts'}
                                                onClick={() => context.onEvent(SCRIPT_ACTIONS.OPEN, 'scripts')}
                                            >
                                                Scripts Management
                                            </Tabs.NavItem>
                                            <Tabs.NavItem
                                                isActive={context.state.active_tab === 'events'}
                                                onClick={() => context.onEvent(SCRIPT_ACTIONS.OPEN, 'events')}
                                            >
                                                Variables
                                            </Tabs.NavItem>
                                            {context.state.open_scripts.map((s) => {
                                                return (
                                                    <TabNavItem
                                                        tips={s.name}
                                                        id={s.script_id}
                                                        key={s.script_id}
                                                        className={classnames(context.state.active_tab === s.script_id ? 'active' : '')}
                                                        isActive={context.state.active_tab === s.script_id}
                                                        menu={() => {
                                                            return (
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-custom-round btn-custom-round-tab"
                                                                    onClick={(e) => {
                                                                        e.preventDefault();
                                                                        e.stopPropagation();
                                                                        context.onEvent(SCRIPT_ACTIONS.CLOSE, s.script_id);
                                                                    }}
                                                                >
                                                                    <Icon name="x" />
                                                                </button>
                                                            );
                                                        }}
                                                        onClick={(e) => {
                                                            if (context.state.active_tab !== s.script_id) {
                                                                context.onEvent(SCRIPT_ACTIONS.OPEN, s);
                                                            }
                                                        }}
                                                    >
                                                        <Icon
                                                            className="mr-5"
                                                            name={ScriptTypesIcon[s.type] || ScriptTypesIcon[ScriptTypes.SCRIPT_STD]}
                                                        />
                                                        <span>{s.name}</span>
                                                    </TabNavItem>
                                                );
                                            })}
                                        </Tabs>
                                        <Tabs.Content activeTab={context.state.active_tab}>
                                            <Tabs.Pane tabId="scripts">
                                                {context.state.active_tab === 'scripts' && (
                                                    <AsyncComponent options={{ onEvent: context.onEvent }} component={() => import('./contentv2')} />
                                                )}
                                            </Tabs.Pane>
                                            <Tabs.Pane tabId="events">
                                                {context.state.active_tab === 'events' && (
                                                    <AsyncComponent component={() => import('./EventsManagement/content')} />
                                                )}
                                            </Tabs.Pane>
                                            {context.state.active_tab.length > 25 && (
                                                <Tabs.Pane tabId={context.state.active_tab}>
                                                    <AsyncComponent
                                                        options={{
                                                            script_id: context.state.active_tab,
                                                            onEvent: context.onEvent,
                                                        }}
                                                        component={() => import('./ScriptView')}
                                                    />
                                                </Tabs.Pane>
                                            )}
                                        </Tabs.Content>
                                    </>
                                );
                            }}
                        </TasksContext.Consumer>
                    </PageContent>
                </ListProvider>
            </PageWrap>
        );
    }
}

TasksManagementPage.propTypes = {
    location: PropTypes.any.isRequired,
    history: PropTypes.any.isRequired,
};

export default withRouter(TasksManagementPage);
